import axios from "axios"

// export const BASE_URL = process.env.NEXT_PUBLIC_API_URL
//   ? process.env.NEXT_PUBLIC_API_URL
//   : 'https://clutchaudience.com';

const buildClient = ({ req, store }) => {
  const baseURL =
    process.env.NEXT_PUBLIC_API_URL || "https://clutchaudience.com"

  let headers = {}

  if (typeof window === "undefined") {
    // We are on the server
    headers = req ? req.headers : {}
    if (req && req.headers.cookie) {
      headers.Cookie = req.headers.cookie
    }
    console.log("&&&&&SERVER**: ", headers.Cookie)

    return axios.create({
      baseURL,
      headers,
      withCredentials: true,
    })
  } else {
    console.log("&&&&&CLIENT**")
    return axios.create({
      baseURL,
    })
  }
}

export default buildClient
