import buildClient from "../api/build-client"
import react, { useState } from "react"
import Link from "next/link"

const LandingPage = ({ currentUser, tickets }) => {
  // if user not logged in --- prompt them to sign up or sign in
  if (!currentUser) {
    return (
      <>
        <div className=''>
          <i className='bi bi-chevron-left position-absolute top-0 start-0'></i>
        </div>
        <Link href='/login?accountType=artist'>
          <button className='btns artist' type='button'>
            Register as an Artist
          </button>
        </Link>
        <Link href='/login?accountType=reviewer'>
          <button className='btns review' type='button'>
            Get Paid to Help Creators
          </button>
        </Link>
        <Link href='/login'>
          <button className='btns log' type='button'>
            Login
          </button>
        </Link>
      </>
    )
  } else {
    return (
      <>
        <div className=''>
          <i className='bi bi-chevron-left position-absolute top-0 start-0'></i>
        </div>
        <Link href='/artist/newrequest'>
          <button className='btns artist' type='button'>
            New Request
          </button>
        </Link>
        <Link href='/aud/neumarketplace'>
          <button className='btns review' type='button'>
            Marketplace
          </button>
        </Link>
      </>
    )
  }

  const [isExternal, setIsExternal] = useState(false)

  const createGroup = () => {
    if (isExternal) {
      // external ui/flow
    } else {
    }
  }

  return (
    <div>
      <div className='big-content-container'>
        <div className='header-plate'>
          <a className='marketplace-link-container header-neumorph-shadow'>
            <img
              className='marketplace-icon'
              src='./icons/header-plate-icons/Group 75market-place-link-icon-svg.svg'
            />
          </a>
          <div className='search-bar neumorph-search-bar-shadow'>
            <form>
              <input type='text' placeholder='Search..' name='search' />
            </form>
          </div>
          <a className='profile-btn-container header-neumorph-shadow'>
            <img
              className='profile-icon'
              src='./icons/header-plate-icons/profile-icon-svg.svg'
            />{" "}
          </a>
        </div>

        <div className='message-container'>
          <button onClick={createGroup} className='new-group-btn'>
            {" "}
            New Group{" "}
          </button>
          <div className='group-face-card'>
            <div className='group-header'>
              <img src='#' alt='group Image' />
              <h1 className='listening-group-name'>Group Name</h1>{" "}
              <span className='num-surveys-completed'>5/30</span> <br />
            </div>
            <div className='group-meta-data'>
              <p>
                Meta Data. You have ....interested in this link. You've
                added...3 paid Dj's.
              </p>
            </div>

            <div className='notification-icon'>
              <p>4</p>
            </div>
          </div>
          <div className='group-face-card'></div>
          <div className='group-face-card'></div>
          <div className='group-face-card'></div>
          <div className='group-face-card'></div>
          <div className='group-face-card'></div>
          <div className='group-face-card'></div>
          <div className='group-face-card'></div>
          <div className='group-face-card'></div>
          <div className='group-face-card'></div>
          <div className='group-face-card'></div>
        </div>
        <nav className='menu-bar-container'>
          <ul className='nav-content'>
            <li className='workplace-menu-btn workplace-home-menu-btn  morph-button-left-shadow'>
              <a> </a>
              <img src='./icons/menu-bar-icons/home-btn-svg.svg' />
            </li>
            <li className='workplace-menu-btn workplace-library-menu-btn morph-button-left-shadow'>
              <a href='#'> </a>
              <img src='./icons/menu-bar-icons/library-btn-svg.svg' />
            </li>
            <li className='workplace-new-group-menu-btn  big-orange-btn'>
              <a href='#'> </a>
              <img src='./icons/menu-bar-icons/add-group-btn-svg.svg' />
            </li>
            <li className='workplace-menu-btn workplace-notifications-menu-btn morph-button-left-shadow '>
              <a href='#'></a>
              <img src='./icons/menu-bar-icons/notifications-btn-svg.svg' />
            </li>
            <li className=' workplace-menu-btn workplace-analytics-menu-btn morph-button-left-shadow'>
              <a href='#'> </a>
              <img src='./icons/menu-bar-icons/analytics-btn-svg.svg' />
            </li>
          </ul>
        </nav>
      </div>
    </div>
  )
}

//getInitialProps is our opportunity to attempt to fetch data this component needs during the server side rendering process
// LandingPage.getInitialProps = async (context, client, currentUser) => {
//   console.log('currentuser is: ', currentUser);
//   const { data } = await client.get('/api/tickets');
//   return { tickets: data, currentUser: currentUser };
// };

export default LandingPage
